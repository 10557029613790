const quotes = [
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Abraham Lincoln",
    quote:
      "My great concern is not whether you have failed, but whether you are content with your failure.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Kyle Chandler",
    quote: "Opportunity does not knock, it presents itself when you beat down the door.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ben Hogan",
    quote:
      "Placing the ball in the right position for the next shot is eighty percent of winning golf.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote:
      "Nature magically suits a man to his fortunes, by making them the fruit of his character.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Harry Emerson Fosdick",
    quote:
      "Picture yourself vividly as winning, and that alone will contribute immeasurably to success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John F. Kennedy",
    quote: "Once you say you're going to settle for second, that's what happens to you in life.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jessica Savitch",
    quote: "No matter how many goals you have achieved, you must set your sights on a higher one.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Benjamin Disraeli",
    quote: "Never apologize for showing feeling. When you do so, you apologize for the truth.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Michael Korda",
    quote: "One way to keep momentum going is to have constantly greater goals.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dwight D. Eisenhower",
    quote:
      "Motivation is the art of getting people to do what you want them to do because they want to do it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Andre Gide",
    quote:
      "One doesn't discover new lands without consenting to lose sight of the shore for a very long time.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert Anthony",
    quote: "Most people would rather be certain they're miserable, than risk being happy.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote:
      "Men succeed when they realize that their failures are the preparation for their victories.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bruce Lee",
    quote: "Mistakes are always forgivable, if one has the courage to admit them.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Abraham Lincoln",
    quote:
      "Nearly all men can stand adversity, but if you want to test a man's character, give him power.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Harriett Beecher Stowe",
    quote: "Never give up, for that is just the place and time that the tide will turn.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dennis A. Peer",
    quote: "One measure of leadership is the caliber of people who choose to follow you.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thomas Edison",
    quote:
      "Opportunity is missed by most people because it is dressed in overalls and looks like work.",
  },
  {
    Type: "Quote",
    Category: "Inspirational",
    author: "Robin Williams (John Keating in Dead Poets Society)",
    quote: "No matter what people tell you, words and ideas can change the world.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert Louis Stevenson",
    quote: "Keep your fears to yourself, but share your inspiration with others.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Johann Wolfgang von Goethe",
    quote: "Knowing is not enough; we must apply. Willing is not enough; we must do.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Laugh when you can, apologize when should, and let go of what you can't change.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Rosabeth Moss Kantor",
    quote: "Leaders are more powerful role models when they learn than when they teach.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John Maxwell",
    quote:
      "Leaders must be close enough to relate to others, but far enough ahead to motivate them.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John Naisbitt",
    quote: "Leadership involves finding a parade and getting in front of it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mary D. Poole",
    quote: "Leadership should be more participative than directive, more enabling than performing.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote: "Life is like riding a bicycle. To keep your balance you must keep moving.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John Lennon",
    quote: "Life is what happens to you while your'e busy making other plans",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mahatma Gandhi",
    quote: "Live as if you were to die tomorrow. Learn as if you were to live forever.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote: "Logic will get you from A to B. Imagination will take you everywhere.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Hope Solo",
    quote: "London 2012 is all about winning a medal. Not just any medal, the gold medal.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Marcus Aurelius",
    quote:
      "Look within. Within is the fountain of good, and it will ever bubble up, if thou wilt ever dig.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Rob Gilbert",
    quote: "Losers visualize the penalties of failure. Winners visualize the rewards of success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Abdul Kalam",
    quote: "Man needs his difficulties because they are necessary to enjoy success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Peter Drucker",
    quote: "Management is doing things right; leadership is doing the right things.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Oliver Wendell Holmes",
    quote:
      "Many ideas grow better when transplanted into another mind, than in the one where they sprung up.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Napoleon Bonaparte",
    quote: "Men take only their needs into consideration, never their abilities.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Conrad Hall",
    quote: "My peers say I have made a difference. That means more to me than winning an Oscar.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Brian Tracy",
    quote:
      "Never consider the possibility of failure; as long as you persist, you will be successful.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Never let yesterday's disappointments overshadow tomorrow's dreams.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Sandra Carey",
    quote:
      "Never mistake knowledge for wisdom. One helps you make a living; the other helps you make a life.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Kamari",
    quote: "Never stop doing your best just because one person doesn't give you credit.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "George Washington Carver",
    quote:
      "Ninety-nine percent of the failures come from people who have the habit of making excuses.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Samuel Goldwyn",
    quote: "No person who is enthusiastic about his work has anything to fear from life.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Maria Robinson",
    quote:
      "Nobody can go back and start a new beginning, but anyone can start today and make a new ending.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William Winans",
    quote: "Not doing more than the average is what keeps the average down.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Charlie Chaplin",
    quote: "Nothing is permanent in this wicked world - not even our troubles.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Samuel Johnson",
    quote: "Nothing will ever be attempted if all possible objections must first be overcome.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Coleman Cox",
    quote: "Now that it's all over, what did you really do yesterday that's worth mentioning?",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Benjamin Disraeli",
    quote: "Nurture your minds with great thoughts. To believe in the heroic makes heroes.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "L. Tom Perry",
    quote: "One of the greatest weaknesses in most of us is our lack of faith in ourselves.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jack Penn",
    quote: "One of the secrets of life is to make stepping stones out of stumbling blocks.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Benjamin Disraeli",
    quote:
      "One secret of success in life is for a man to be ready for his opportunity when it comes.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "T.S. Elliot",
    quote: "Only those who will risk going too far can possibly find out how far one can go.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Helen Keller",
    quote:
      "Optimism is the faith that leads to achievement. Nothing can be done without hope and confidence.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Martin Luther King Jr",
    quote: "Our lives begin to end the day we become silent about things that matter.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Napoleon Hill",
    quote: "Patience, persistence and perspiration make an unbeatable combination for success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jean Sibelius",
    quote: "Pay no attention to what the critics say;no statue has ever been erected to a critic.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lee Westwood",
    quote: "People confuse being world No. 1 with winning a Major championship.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lewis Cass",
    quote: "People may doubt what you say, but they will believe what you do.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote: "People seem not to see that their opinion of the world is a confession of character.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Walter Elliot",
    quote: "Perseverance is not a long race;it is many short races one after the other.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Elbert Hubbard",
    quote: "Pray that success will not come any faster than you are able to endure it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William R. Alger",
    quote: "Proverbs are mental gems gathered in the diamond districts of the mind.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Swami Sivananda",
    quote:
      "Put your heart, mind, and soul into even your smallest acts. This is the secret of success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "E.M. Kelly",
    quote:
      "Remember the difference between a boss and a leader; a boss says Go! a leader says Let's go!",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thornton Wilder",
    quote: "Seek the lofty by reading, hearing and seeing great work at some moment every day.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Tony Robbins",
    quote: "Setting goals is the first step in turning the invisible into the visible.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote: "Shallow men believe in luck. Strong men believe in cause and effect.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Paul Bryant",
    quote:
      "Show class, have pride, and display character. If you do, winning takes care of itself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Michael Jordan",
    quote: "Some people want it to happen, some wish it would happen, others make it happen.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Earl Campbell",
    quote: "Somebody will always break your records. It is how you live that counts.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Warren Buffett",
    quote: "Someone's sitting in the shade today because someone planted a tree a long time ago.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Peter Anderson",
    quote: "Stealing someone else's words frequently spares the embarrassment of eating your own.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Winston Churchill",
    quote: "Success consists of going from failure to failure without loss of enthusiasm.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Josh Billings",
    quote:
      "Success does not consist in never making blunders, but in never making the same one a second time.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Sam Ewing",
    quote: "Success has a simple formula: do your best, and people may like it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bill Gates",
    quote: "Success is a lousy teacher. It seduces smart people into thinking they can't lose.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Oscar Wilde",
    quote: "Success is a science;if you have the conditions, you get the result.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Wilfred Peterson",
    quote:
      "Success is focusing the full power of all you are on what you have a burning desire to achieve.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dale Carnegie",
    quote: "Success is getting what you want. Happiness is wanting what you get.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Sir Winston Churchill",
    quote: "Success is going from failure to failure without a loss of enthusiasm.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Winston Churchill",
    quote: "Success is not final, failure is not fatal: it is the courage to continue that counts.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Al Bernstein",
    quote: "Success is often the result of taking a misstep in the right direction.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Arnold H. Glasgow",
    quote: "Success is simple. Do what's right, the right way, at the right time.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Evan Esar",
    quote:
      "Success is the good fortune that comes from aspiration, desperation, perspiration and inspiration.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Paul J. Meyer",
    quote: "Success is the progressive realization of predetermined, worthwhile, personal goals.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert Collier",
    quote: "Success is the sum of small efforts, repeated day in and day out.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Arnold H. Glasow",
    quote: "Success isn't a result of spontaneous combustion. You must set yourself on fire.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William Feather",
    quote: "Success seems to be largely a matter of hanging on after others have let go.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Barbra Streisand",
    quote:
      "Success to me is having ten honeydew melons and eating only the top half of each slice.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John Maxwell",
    quote: "Successful leaders have the courage to take action while others hesitate.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Abraham Lincoln",
    quote: "That some achieve great success, is proof to all that others can achieve it as well.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jean-Claude Killy",
    quote: "The best and fastest way to learn a sport is to watch and imitate a champion.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "They are shameless thieves.",
    quote: "The best leaders are the best notetakers, best askers and best learners.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Abraham Lincoln",
    quote: "The best thing about the future is that it only comes one day at a time.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Art Rooney",
    quote: "The biggest thrill wasn't in winning on Sunday but in meeting the payroll on Monday.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote: "The definition of madness is doing the same thing and excpecting a different result.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Tommy Lasorda",
    quote:
      "The difference between the impossible and the possible lies in a person's determination.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Peter Thomson",
    quote: "The difference between winning and losing is always a mental one.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bruce Feirstein",
    quote: "The distance between insanity and genius is measured only by success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lucretius",
    quote: "The drops of rain make a hole in the stone, not by violence, but by oft falling.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Peter Drucker",
    quote:
      "The entrepreneur always searches for change, responds to it, and exploits it as an opportunity.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bear Bryant",
    quote: "The fun never goes out, but it changes with the years, with winning and losing.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Nader",
    quote: "The function of leadership is to produce more leaders, not more followers.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Eleanor Roosevelt",
    quote: "The future belongs to those who believe in the beauty of their dreams.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Elbert Hubbard",
    quote:
      "The greatest mistake you can make in life is to continually be afraid you will make one.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert E. Moody",
    quote: "The greatest motivational act one person can do for another is to listen.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Daniel J. Boorstin",
    quote: "The greatest obstacle to discovery is not ignorance - it is the illusion of knowledge.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Walter Bagehot",
    quote: "The greatest pleasure in life is doing what people say you cannot do.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Philip Emeagwali",
    quote: "The hardships that I encountered in the past will help me succeed in the future.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Epictetus",
    quote:
      "The key is to keep company only with people who uplift you, whose presence calls forth your best.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ayn Rand",
    quote: "The ladder of success is best climbed by stepping on the rungs of opportunity.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jack Nicklaus",
    quote: "The longer you play, the better chance the better player has of winning.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Roger Bannister",
    quote:
      "The man who can drive himself further once the effort gets painful is the man who will win.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "James Crook",
    quote: "The man who wants to lead the orchestra must turn his back on the crowd.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Richard Bach",
    quote: "The meaning I picked, the one that changed my life: Overcome fear, behold wonder.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Francis Bacon",
    quote: "The momentous thing in human life is the art of winning the soul to good or evil.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Confucius",
    quote:
      "The more man meditates upon good thoughts, the better will be his world and the world at large.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Andre Maurois",
    quote: "The most important quality in a leader is that of being acknowledged as such.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Pythagoras",
    quote: "The most momentous thing in human life is the art of winning the soul to good or evil.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Alan Price",
    quote:
      "The one phrase you can use is that success has a thousand fathers, and failure is an orphan.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Kristinna Habashy",
    quote: "The only limitations you will ever have are the ones you put on yourself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Arthur C. Clarke",
    quote:
      "The only way of finding the limits of the possible is by going beyond them into the impossible.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Apple",
    quote:
      "The people who are crazy enough to think they can change the world are the ones who do.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mia Hamm",
    quote: "The person that said winning isn't everything, never won anything.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Miller, The Wisdom of the Heart",
    quote: "The real leader has no need to lead he is content to point the way.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Zig Ziglar",
    quote: "The real opportunity for success lies within the person and not in the job.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Justus Liebig",
    quote:
      "The secret of all those who make discoveries is that they regard nothing as impossible.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henri B. Stendhal",
    quote:
      "The shepherd always tries to persuade the sheep that their interests and his own are the same.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dale Carnegie",
    quote: "The successful man will profit from his mistakes and try again in a different way.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bella Abzug",
    quote:
      "The test for whether or not you can hold a job should not be the arrangement of your chromosomes.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Salvador Dali",
    quote: "The thermometer of success is merely the jealousy of the malcontents.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Frank Loyd Wright",
    quote:
      "The thing always happens that you really believe in; and the belief in a thing makes it happen.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote:
      "The toughest part of getting to the top of the ladder, is getting through the crowd at the bottom.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Irving Berlin",
    quote: "The toughest thing about success is that you've got to keep on being a success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Muhammed Iqbal",
    quote: "The ultimate aim of the ego is not to see something, but to be something.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Theodore Hesburgh",
    quote: "The very essence of leadership is that you have to have a vision.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mahatma Gandhi",
    quote: "The weak can never forgive. Forgiveness is the attribute of the strong.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bobby Knight",
    quote: "The will to succeed is important, but what's more important is the will to prepare.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "H. W. Arnold",
    quote: "The worst bankruptcy in the world is the person who has lost his enthusiasm.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William Frederick Halsy, Jr.",
    quote:
      "There are no great people in this world, only great challenges which ordinary people rise to meet.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Roger H. Lincoln",
    quote: "There are two rules for success: 1) Never tell everything you know.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Edith Wharton",
    quote:
      "There are two ways of spreading light: to be the candle or the mirror that reflects it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Alexandre Ledru Rollin",
    quote: "There go my people.  I must find out where they are going so I can lead them.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Richard Rorty",
    quote: "There is nothing deep down inside us except what we have put there ourselves.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "James Boswell",
    quote: "There is nothing worth the wear of winning, but laughter and the love of friends.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "David Burns",
    quote: "There is only one person who could ever make you happy, and that person is you.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Christopher Morley",
    quote: "There is only one success - to be able to spend your life in your own way.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote:
      "There is real magic in enthusiasm. It spells the difference between mediocrity and accomplishment.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Ford",
    quote: "There isn't a person anywhere that isn't capable of doing more than he thinks he can.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Andy Warhol",
    quote: "They always say time changes things, but you actually have to change them yourself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Edgar Allan Poe",
    quote:
      "They who dream by day are cognizant of many things which escape those who dream only by night.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Abraham Lincoln",
    quote: "Things may come to those who wait, but only things left by those who hustle.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John Wooden",
    quote: "Things turn out best for people who make the best of the way things turn out.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Larry King",
    quote: "Those who have succeeded at anything and don't mention luck are kidding themselves.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Those who say it can't be done are being passed by those doing it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert Louis Stevenson",
    quote: "To be wholly devoted to some intellectual exercise is to have succeeded in life.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote:
      "To know even one life has breathed easier because you have lived. This is to have succeeded.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Nicholas Charles Trublet",
    quote: "To select well among old things, is almost equal to inventing new ones.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mark Twain",
    quote: "To succeed in life, you need two things: ignorance and confidence.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Michael Korda",
    quote: "To succeed it is necessary to accept the world as it is and rise above it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Sahsa Azevedo",
    quote:
      "Too many people go through life waiting for things to happen instead of making them happen.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Maurice Seitter",
    quote: "Too many people miss the silver lining because they're expecting gold.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John W. Gardner",
    quote: "True happiness involves the full use of one's power and talents.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote: "Try not to become a man of success but rather try to become a man of value.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Wanting to be a success is aspiration. Doing something about it is perspiration.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Oscar Wilde",
    quote: "We are all in the gutter, but some of us are looking at the stars.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Winston Churchill",
    quote: "We are still masters of our fate.We are still captains of our souls.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Aristotle",
    quote: "We are what we repeatedly do. Excellence, therefore, is not an act but a habit.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Tony Robbins",
    quote: "We can change our lives. We can do, have, and be exactly what we wish.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William James",
    quote:
      "We have to live today by what truth we can get today and be ready tomorrow to call it falsehood.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Benjamin Franklin",
    quote: "We must all hang together, or assuredly, we shall all hang separately.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Marilyn Monroe",
    quote: "We should all start to live before we get too old. Fear is stupid. So are regrets.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "We should not let success go to our heads, or our failures go to our hearts.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bill Gates",
    quote: "We were young, but we had good advice and good ideas and lots of enthusiasm.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "C. Malesherbe s",
    quote: "We would accomplish many more things if we did not think of them as impossible.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Napolean Hill",
    quote: "What ever the mind of man can conceive and believe, it can achieve.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert H. Schuller",
    quote: "What great thing would you attempt if you knew you could not fail?",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote:
      "What lies behind us and what lies before us are tiny matters compared to what lies within us.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Emil Brunner",
    quote: "What oxygen is to the lungs, such is hope to the meaning of life.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Maya Angelou",
    quote: "A bird doesn't sing because it has an answer, it sings because it has a song.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Billie Jean King",
    quote: "A champion is afraid of losing. Everyone else is afraid of winning.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William B. Sprague",
    quote: "A collection of rare thoughts is nothing less than a cabinet of intellectual gems.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ayn Rand",
    quote:
      "A creative man is motivated by the desire to achieve, not by the desire to beat others.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bruce Lee",
    quote:
      "A goal is not always meant to be reached. It often serves simply as something to aim at.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William James",
    quote:
      "A great many people think they are thinking when they are merely rearranging their prejudices.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote: "A hero is no braver than an ordinary man, but he is braver five minutes longer.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John C. Maxwell",
    quote: "A leader is one who knows the way, goes the way, and shows the way.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "James Callaghan",
    quote: "A leader must have the courage to act against an expert's advice.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Golda Meir",
    quote:
      "A leader who doesn't hesitate before he sends his nation into battle is not fit to be a leader.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Michel de Montaigne",
    quote: "When I quote others I do so in order to express my own ideas more clearly.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Richard Hooker",
    quote: "When the best things are not possible, the best may be made of those that are.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "When the world says, Give up, Hope whispers, 'Try it one more time.'",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Natalie Gulbis",
    quote:
      "When you fail you learn from the mistakes you made and it motivates you to work even harder.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "When you feel like giving up, remember why you held on for so long in the first place.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thomas Jefferson",
    quote: "When you reach the end of your rope, tie a knot in it and hang on.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Ford",
    quote: "Whether you think you can or whether you think you can't, you're right.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "George Orwell",
    quote: "Whoever is winning at the moment will always seem to be invincible.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jack Kemp",
    quote: "Winning is like shaving - you do it every day or you wind up looking like a bum.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "George Steinbrenner",
    quote:
      "Winning is the most important thing in my life, after breathing. Breathing first, winning next.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ayrton Senna",
    quote: "Winning is the most important. Everything is consequence of that.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Paul Bryant",
    quote: "Winning isn't everything, but it beats anything that comes in second.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Amos Alonzo Stagg",
    quote: "Winning isn't worthwhile unless one has something finer and nobler behind it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dianne Feinstein",
    quote: "Winning may not be everything, but losing has little to recommend it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Vaclav Havel",
    quote: "Work for something because it is good, not just because it stands a chance to succeed.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Worry does not empty tomorrow of its sorrow;it empties today of its strength.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote:
      "Worrying is like a rocking chair, it gives you something to do, but it doesn't get you anywhere",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Richard Bach",
    quote:
      "You are always free to change your mind and choose a different future, or a different past.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "C. S. Lewis",
    quote: "You are never too old to set another goal or to dream a new dream.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Flip Wilson",
    quote: "You can't expect to hit the jackpot if you don't put a few nickels in the machine.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Charles F. Kettering",
    quote: "You can't have a better tomorrow if you are always thinking about yesterday.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Gene Mauch",
    quote: "You can't lead anyone else further than you have gone yourself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jack London",
    quote: "You can't wait for inspiration. You have to go after it with a club.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dwight D. Eisenhower",
    quote: "You do not lead by hitting people over the head.  That's assault, not leadership.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Alan Watts",
    quote: "You don't look out there for God, something in the sky, you look in you.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dr. Wayne Dyer",
    quote:
      "You have a very powerful mind that can make anything happen as long as you keep yourself centered.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Vernon Howard",
    quote: "You have succeeded in life when all you really want is only what you really need.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote:
      "You have to learn the rules of the game. And then you have to play better than anyone else.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jeb Bush",
    quote: "You know, when you run you got to be all in and you take risks of winning and losing.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Chuck Noll",
    quote: "A life of frustration is inevitable for any coach whose main enjoyment is winning.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Charles Schwab",
    quote: "A man can succeed at almost anything for which he has unlimited enthusiasm.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Richard Nixon",
    quote: "A man is not finished when he is defeated. He is finished when he quits.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mary Kay Ash",
    quote:
      "A mediocre idea that generates enthusiasm will go farther than a great idea that inspires no one.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William Shedd",
    quote: "A ship in harbour is safe, but that is not what ships are built for.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "David Brinkley",
    quote:
      "A successful man is one who can lay a firm foundation with the bricks others have thrown at him.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "A wise man can see more from the bottom of a well than a fool can from a mountain top.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "John Wooden",
    quote: "Ability may get you to the top, but it takes character to keep you there.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mitch Albom",
    quote: "All endings are also beginnings. We just don't know it at the time.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Walt Disney",
    quote: "All our dreams can come true – if we have the courage to pursue them.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mark Twain",
    quote: "All you need is ignorance and confidence and the success is sure.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Abraham Lincoln",
    quote:
      "Always bear in mind that your own resolution to succeed is more important than any other.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Og Mandino",
    quote: "Always do your best. What you plant now, you will harvest later.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Sarah Ban Breathnach",
    quote: "Always remember that striving and struggle precede success, even in the dictionary.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Donald Cargill",
    quote: "And now, this is the sweetest and most glorious day that ever my eyes did see.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote: "Anyone who has never made a mistake has never tried anything new.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dan O'Brien",
    quote: "As a young athlete, it was first about having fun;then it was about winning.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Anne Bradstreet",
    quote:
      "Authority without wisdom is like a heavy axe without an edge, fitter to bruise than polish.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Be enthusiastic as a leader. You can't light a fire with a wet match.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Wayne Dyer",
    quote: "Be miserable. Or motivate yourself. Whatever has to be done, it's always your choice.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Martha Plimpton",
    quote:
      "Being nominated is the win. For me, being nominated is winning. It's just unbelievable.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Margaret Thatcher",
    quote: "Being powerful is like being a lady. If you have to tell people you are, you aren’t!",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lydia M. Child",
    quote:
      "Belief in oneself is one of the most important bricks in building any successful venture.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Orison Swett Marden",
    quote: "Believe with all of your heart that you will do what you were made to do.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote: "Big jobs usually go to the men who prove their ability to outgrow small ones.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Napoleon Hill",
    quote: "Big pay and little responsibility are circumstances seldom found together.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dave Bristol",
    quote: "Boys, baseball is a game where you gotta have fun. You do that by winning.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "George S. Patton",
    quote: "You need to overcome the tug of people against you as you reach for high goals.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "George Bernard Shaw",
    quote:
      "You see things; and you say 'Why?' But I dream things that never were; and I say 'Why not?'",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lou Holtz",
    quote:
      "You were not born a winner, and you were not born a loser. You are what you make yourself be.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Maya Angelou",
    quote: "You will face many defeats in your life, but never let yourself be defeated.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Nido Qubein",
    quote:
      "Your present circumstances don't determine where you can go;they merely determine where you start.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Orson Scott Card",
    quote:
      "Your work is first, learning is first, winning is everything because without it there is nothing.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Phillips Brooks",
    quote: "Character may be manifested in the great moments, but it is made in the small ones.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Ford",
    quote:
      "Coming together is a beginning; keeping together is progress; working together is success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Peter T. Mcintyre",
    quote: "Confidence comes not from always being right but not fearing to be wrong.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Winston Churchill",
    quote: "Courage is going from failure to failure without losing enthusiasm.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Tom Crause",
    quote: "Courage is the discovery that you may not win, and trying when you know you can lose.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Swami Sivananda",
    quote:
      "Crave for a thing, you will get it. Renounce the craving, the object will follow you by itself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "A. Sachs",
    quote: "Death is more universal than life; everyone dies but not everyone lives.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "George Edward Woodberry",
    quote: "Defeat is not the worst of failures. Not to have tried is the true failure.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "W. Clement Stone",
    quote: "Definiteness of purpose is the starting point of all achievement.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William E. Channing",
    quote:
      "Difficulties are meant to rouse, not discourage. The human spirit is to grow strong by conflict.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "James MacGregor Burns",
    quote:
      "Divorced from ethics, leadership is reduced to management and politics to mere technique.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote:
      "Do not go where the path may lead, go instead where there is no path and leave a trail.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "William B. Sprague",
    quote: "Do not wait to strike till the iron is hot; but make it hot by striking.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thomas Jefferson",
    quote: "Do you want to know who you are? Don't ask. Act! Action will delineate and define you.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Elbert Hubbard",
    quote:
      "Do your work with your whole heart, and you will succeed - there's so little competition.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Don't be discouraged. It's often the last key in the bunch that opens the lock.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Don't compare your life to others. You have no idea what their journey is all about.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Paul Bryant",
    quote: "Don't give up at half time. Concentrate on winning the second half.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert Louis Stevenson",
    quote: "Don't judge each day by the harvest you reap but by the seeds that you plant.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Don't judge those who try and fail, judge those who fail to try.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Isaac Disraeli",
    quote:
      "Enthusiasm is that secret and harmonious spirit which hovers over the production of genius.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Chester",
    quote: "Enthusiasm is the greatest asset in the world. It beats money, power and influence",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "E. O. Wilson",
    quote: "Even as empiricism is winning the mind, transcendentalism continues to win the heart.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Martin Luther",
    quote:
      "Even if I knew that tomorrow the world would go to pieces, I would still plant my apple tree.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Every leader needs to look back once in a while to make sure he has followers.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thomas Merton",
    quote:
      "Every moment and every event of every man's life on earth plants something in his soul.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lisa Leslie",
    quote:
      "Everyone talks about age, but it's not about age. It's about work ethic. Winning never gets old.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Everything will be OK in the end, if it's not OK, it's not the end.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Everytime you are not practicing, someone else is...when you play them, they will win.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Schweitzer",
    quote: "Example is not the main thing in influencing others.  It is the only thing.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Aldous Huxley",
    quote: "Experience is not what happens to you; it is what you do with what happens to you.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Failure is only the opportunity to begin again, this time more wisely.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Morihei Ueshiba",
    quote: "Failure is the key to success;each mistake teaches us something.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Joseph Campbell",
    quote: "Find a place inside where there's joy, and the joy will burn out the pain.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dinesh D'Souza",
    quote: "For me, victory isn't measured by winning in the traditional sense.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "James Allen",
    quote:
      "For true success ask yourself these four questions: Why? Why not? Why not me? Why not now?",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jim Rohn",
    quote: "Formal education will make you a living;self-education will make you a fortune.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bo Bennett",
    quote:
      "Frustration, although quite painful at times, is a very positive and essential part of success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote: "Great spirits have always encountered violent opposition from mediocre minds.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Vincent van Gogh",
    quote: "Great things are done by a series of small things brought together.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Samuel Johnson",
    quote: "Great works are performed not by strength, but by perseverance.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Ralph Waldo Emerson",
    quote:
      "Happiness is a perfume you cannot pour on others without getting a few drops on yourself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jim Rohn",
    quote:
      "Happiness is not something you postpone for the future;it is something you design for the present.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Franklin D. Roosevelt",
    quote: "Happiness lies in the joy of achievement and the thrill of creative effort.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Democritus",
    quote: "Happiness resides not in possessions, and not in gold, happiness dwells in the soul.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Leon J. Suenes",
    quote:
      "Happy are those who dream dreams and are ready to pay the price to make them come true.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Benjamin Franklin",
    quote: "He that is good for making excuses is seldom good for anything else.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Confucius",
    quote: "He who speaks without modesty will find it difficult to make his words good.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Samuel Smiles",
    quote:
      "Hope is like the sun, which, as we journey toward it, casts the shadow of our burden behind us.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thornton Wilder",
    quote:
      "Hope, like faith, is nothing if it is not courageous;it is nothing if it is not ridiculous.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "How a man plays a game shows something of his character, how he loses shows all of it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Pablo Picasso",
    quote: "I am always doing that which I cannot do, in order that I may learn how to do it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jimmy Dean",
    quote:
      "I can't change the direction of the wind, but I can adjust my sails to always reach my destination.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Bill Veeck",
    removed: true,
    quote:
      "I do not think that winning is the most important thing. I think winning is the only thing.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Oprah Winfrey",
    quote: "I don't believe in failure. It's not failure if you enjoyed the process.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "George S. Patton",
    quote:
      "I don't measure a man's success by how high he climbs but how high he bounces when he hits bottom.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Michael Jordan",
    quote: "I have failed over and over gain in my life and that is why I succeed.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Pope John XXIII",
    quote: "I have looked into your eyes with my eyes. I have put my heart near your heart.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Boris Becker",
    quote: "I love the winning, I can take the losing, but most of all I Love to play.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jennie Finch",
    quote: "I mean, I love winning, but losing is a much more intense feeling.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Indira Gandhi",
    quote:
      "I suppose that leadership at one time meant muscle; but today it means getting along with people.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Christine Lahti",
    quote:
      "I was a fighter and very determined. There was no way I was going to back out without winning.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Donna Brazile",
    quote: "I was motivated to be different in part because I was different.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Robert H. Schuller",
    quote:
      "I'd rather attempt to do something great and fail than to attempt to do nothing and succeed.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Ford",
    quote: "If everyone is moving forward together, then success takes care of itself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Issac Newton",
    quote:
      "If I have seen farther than others, it is because I was standing on the shoulder of giants.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Neale Donald Walsch",
    quote: "If the whole world followed you, would you be pleased with where you took it?",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Latin Proverb",
    quote: "If the wind will not serve,take to the oars.Destitutus ventis, remos adhibe",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thomas Edison",
    quote: "If we did all the things we are capable of, we would astound ourselves.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Walter Reuther",
    quote: "If you are not big enough to lose, you are not big enough to win.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Emile Zola",
    quote:
      "If you ask me what I came into this life to do, I will tell you: I came to live out loud.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Brian Tracy",
    quote: "If you do not do the thing you fear, the fear controls your life.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "St. Clement of Alexandra",
    quote: "If you do not hope, you will not find what is beyond your hopes.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Cavett Robert",
    quote: "If you don't think every day is a good day, just try missing one.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Cher",
    quote: "If you really want something, you can figure out how to make it happen.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Ford",
    quote: "If you think you can, you can. And if you think you can't, you're right.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Dale Carnegie",
    quote: "If you want to conquer fear, don't sit home and think about it. Go out and get busy.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "E. Joseph Cossman",
    quote:
      "If you want to test your memory, try to recall what you were worrying about one year ago today.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Thomas Jefferson",
    quote:
      "In matters of style, swim with the current; in matters of principle, stand like a rock.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Anthony J. D'Angelo",
    quote: "In order to succeed you must fail, so that you know what not to do the next time.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Sir Francis Bacon",
    quote:
      "In taking revenge, a man is but even with his enemy; but in passing it over, he is superior.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote:
      "In the end, the size of a person's accomplishment can best be measured by the size of their heart.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Daniel L. Reardon",
    quote:
      "In the long run the pessimist may be proved right, but the optimist has a better time on the trip.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry David Thoreau",
    quote:
      "In the long run, men hit only what they aim at. Therefore, they had better aim at something high.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Henry Ward Beecher",
    quote: "In this world it is not what we take up, but what we give up, that makes us rich.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Charles Simic",
    quote:
      "Inside my empty bottle I was constructing a lighthouse while all the others were making ships.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "Instead of giving myself reasons why I can't, I give myself reasons why I can.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Brian Tracy",
    quote: "It doesn't matter where you are coming from. All that matters is where you are going.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Gary Burton",
    quote:
      "It hasn't been hard getting nominated, but winning it is another thing. The competition is tough.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Sandra Swinney",
    quote:
      "It is amazing how much people can get done if they do not worry about who gets the credit.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "James Thurber",
    quote: "It is better to know some of the questions than all of the answers.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Aristotle Onassis",
    quote: "It is during our darkest moments that we must focus to see the light.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Napoleon Hill",
    quote:
      "It is literally true that you can succeed best and quickest by helping others to succeed.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Winston Churchill",
    quote:
      "It is no use saying, 'We are doing our best.' You have got to succeed in doing what is necessary.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Joseph Wood Krutch",
    quote: "It is not ignorance but knowledge which is the mother of wonder.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Havelock Ellis",
    quote: "It is on our failures that we base a new and different and better success.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Amelia Barr",
    quote: "It is only in sorrow bad weather masters us;in joy we face the storm and defy it.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lucille Ball",
    quote: "It's a helluva start being able to recognize what makes you happy.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Lynn Davies",
    quote: "It's about lessons for life. It's not just about winning either.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Antonio Davis",
    quote: "It's fool's gold if you are winning games and are not playing the right way.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Albert Einstein",
    quote: "It's not that I'm so smart, it's just that I stay with problems longer.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Mark Twain",
    quote: "It's not the size of the dog in the fight, but the size of the fight in the dog.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote: "It's not who you are that holds you back, it's who you think you're not.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Summer Altice",
    quote:
      "It's not winning or losing. It's the friends and the people that you meet along the way.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Carrot Top",
    quote: "It's our nature: Human beings like success but they hate successful people.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Rahm Emanuel",
    quote: "I've never been in a place where winning has hurt the ability to do anything.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Jean Paul",
    quote:
      "Joy descends gently upon us like the evening dew, and does not patter down like a hailstorm.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Seth Godin",
    quote: "Just because something is easy to measure doesn't mean it's important.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Theodore T. Hunger",
    quote: "Keep steadily before you the fact that all true success depends at last upon yourself.",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "African Proverb",
    quote: "Smooth seas do not make skillful sailors",
  },
  {
    Type: "Quote",
    Category: "Motivational",
    author: "Unknown",
    quote:
      "Marriage is hard. Divorce is hard. Choose your hard.Obesity is hard. Being fit is hard. Choose your hard.Being in debt is hard. Being financially disciplined is hard. Choose your hard.Communication is hard. Not communicating is hard. Choose your hard.Life will never be easy. It will always be hard. But we can choose our hard.Pick wisely.",
  },
];

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const customQuotes = [
  {
    quote:
      "I am very concerned that our society is much more interested in information than wonder, in noise rather tahn silence.",
    author: "Fred Rogers",
  },
  {
    quote:
      " Happiness is both a dream and a phantom, the further we seek it the further we are left behind.",
    author: " Adapted from Hewitt",
  },
  {
    quote: "When you ask the world for healing, you begin to see it everywhere you look.",
    author: "Emily Pennington",
  },
  {
    quote:
      "Courage isn't something we're born with; it's forged in the fire of our own experience.",
    author: "Emily Pennington",
  },
  {
    quote: "Your melanchalia is a luxury.",
    author: "Srećko Horvat",
  },
  {
    quote: "The past is a ghost, insubstantial, unaffecting. Only the future has weight.",
    author: "Educated A Memoir Author",
  },
  {
    quote: "Stalk yourself.",
    author: "Kevin Cokley",
  },
  {
    quote:
      "As I make hundreds of small choices throughout the day, I’m building a life—but at one and the same time, I’m closing off the possibility of countless others, forever.",
    author: "Oliver Burkeman",
  },
  {
    quote: "You were there when I needed you, even if you didn’t know it.",
    author: "Unknown",
  },
  {
    quote: "Remembering and speaking give birth to resistance.",
    author: "Srećko Horvat",
    book: "Poetry from the Future",
  },
  {
    quote:
      "...our task today is to be aware of the steps we are taking: to realize that the real question is not how did all this - Donald Trump, the disintegration of the EU, refugee camps, walls, climate change and new wars- come about, but what were the steps we ourselves took during all this.",
    author: "Srećko Horvat",
    book: "Poetry from the Future",
  },
  {
    quote: "Eternal vigilance is the price of liberty.",
    author: "Wendell Phillips",
    book: "On Tyranny: Twenty Lessons from the Twentieth Century",
  },
  {
    quote: "Better a diamond with a flaw than a pebble without.",
    author: "Confucius",
  },
  {
    quote:
      "But one of the core rules to make the Zettelkasten work for you is to use your own words, instead of just copying and pasting something you believe is useful or insightful. This forces you to at least create a different version of it, your own version.",
    author: "",
  },
  {
    quote: "You should always translate information to knowledge by adding context and relevance.",
    author: "",
  },
  {
    quote: "Each new Zettel needs to be placed in some relationship to another Zettel.",
    author: "",
  },
  {
    quote: "Starting is always better than not doing a thing.",
    author: "",
  },
  {
    quote:
      "Your creation will be imperfect, as you are imperfect. And from our imperfections, the power of the unpredictable and the beauty of the unknown will arise.",
    author: "@Spatial_Punnk",
  },
  {
    quote: "Decolonize time from the empire of the present.",
    author: "From an interview with Srecko Horvat, interviewer.",
  },
  {
    quote:
      "I think that's just how the world will come to an end; to general applause from wits who believe it's a joke.",
    author: "Søren Kierkegaard, Either/Or 1843",
  },
  {
    quote:
      "Be aware of your passion, follow your passion, use the apocalypse as an opportunity for reflection, and create a world in which you want to live.",
    author: "Srecko Horvat, 2021 - Interview on the Urbanistica Podcast",
  },
  {
    quote:
      "That is a very powerful thing, to tell someone who feels perhaps they own very little, to find out they own the world.",
    author: "Shelton Johnson",
  },
  {
    quote: "Cultivate a relationship with risk.",
    author: "Cait Scudder",
  },
  {
    quote: "You can cut all the flowers, but you cannot prevent spring from coming.",
    author: "Pablo Neruda",
  },
  {
    quote:
      "It's hard for me to think of a single democratic advance in this country that was not accompanied by if not occasioned by an intense polarization in the socciety.",
    author: "Corey Robin",
  },
];

const getQuote = async () => {
  try {
    // const jsonBinQuotes = await fetch("https://api.jsonbin.io/v3/b/645a3a8f9d312622a35abfda", {
    //   headers: {
    //     "X-Access-Key": "$2b$10$1LaM1xRj.tMMz8kRYrBW8OnGqUbABZwUyPFB3kOCondZaL/dnEX2O",
    //   },
    // }).then((res) => res.json());
    // const data = jsonBinQuotes.record;
    // console.log(data);
    quotes.push(...customQuotes);
    const filtered = quotes.filter((quote) => !quote.removed);
    const randomQuote = filtered[getRandomInt(364)];
    return randomQuote;
  } catch (error) {
    console.log("{quote error}");
    console.log({ error });
    return {
      author: "African Proverb",
      quote: "Smooth seas do not make skillful sailors",
    };
  }
};

export default getQuote;
