import PocketBase from "pocketbase";
import getQuote from "./quotes";

(async () => {
  let user;

  const url = "https://simple-habits.pockethost.io/";
  const pb = new PocketBase(url);

  pb.authStore.onChange(async (token, model) => {
    console.log("New store data:", token, model);
    if (token) {
      // document.querySelector(".js-login").classList.remove("active");
      user = { ...model, token };
      console.log(user);
      await initHabits();
      document.querySelector(".loader").style.display = "none";
    }
  });

  const loginForm = document.querySelector("#login-form");
  loginForm.addEventListener("submit", (e) => {
    e.preventDefault();
    document.querySelector(".loader").style.opacity = 1;
    document.querySelector(".loader").style.display = "flex";
    window.location.hash = "";
    const data = new FormData(loginForm);
    const password = data.get("password");
    const email = data.get("email");
    pb.collection("users")
      .authWithPassword(email, password)
      .catch((error) => {
        console.log(error);
        user = null;
        document.querySelector(".login-link a").innerText = "Login";
        document.querySelector(".loader").style.display = "none";
        window.location.hash = "#login";
      });
  });

  const logoutHandler = document.querySelector(".login-link a");
  logoutHandler.addEventListener("click", (e) => {
    if (user) {
      e.preventDefault();
      window.hash = "";
      pb.authStore.clear();
      logoutHandler.innerText = "Login";
      document.querySelector("main").innerHTML = "";
      user = null;
      return;
    }
  });

  // example create data
  // const data = {
  //   username: "",
  //   email: "",
  //   emailVisibility: true,
  //   password: "",
  //   passwordConfirm: "",
  //   name: "Malcolm Meyer",
  // };

  //register user
  // try {
  //   const _user = await pb.collection("users").create(data);
  //   user = _user;
  // } catch (error) {
  //   console.log(error);
  // }

  //login user
  try {
    // const auth = await pb.collection("users").authWithPassword(data.username, data.password);
    // console.log(auth);
    const _user = pb?.authStore?.model;
    if (_user && pb.authStore.isValid) {
      user = { ..._user, token: pb.authStore.token };
      console.log(user);
      await initHabits();
    }
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }

  async function initHabits() {
    document.querySelector(".login-link a").innerText = "Logout";
    const allHabitNames = await pb.collection("habits").getList(1, 100, {
      filter: `user = "${user.id}"`,
      fields: "habit",
    });

    if (!allHabitNames?.items?.length) {
      const alert = document.createElement("div");
      alert.className = "missing-habits";
      alert.innerText = "You don't have any habits yet. Click the button below to create one.";
      document.querySelector("main").appendChild(alert);
    } else {
      for (let i = 0; i < allHabitNames.items.length; i++) {
        const item = allHabitNames.items[i];
        console.log(item);
        await createHabit(item.habit, "main", i);
      }
    }
  }

  const createHabitButton = document.createElement("button");
  createHabitButton.innerText = "Create New Habit";
  createHabitButton.addEventListener("click", async () => {
    if (!user) {
      window.location.hash = "#login";
      return;
    }
    const name = prompt("What is the name of your habit?");
    await createHabit(name, "main");
  });
  document.querySelector(".js-create").appendChild(createHabitButton);

  const quote = await getQuote();
  if (quote) {
    const bq = document.createElement("blockquote");
    bq.innerText = `"${quote?.quote}" ~ ${quote?.author}`;
    document.querySelector(".js-quote").appendChild(bq);
    document.querySelector(".wrapper").style.opacity = 1;
    document.querySelector(".loader").style.opacity = 0;

    setTimeout(() => {
      bq.style.opacity = 1;
      document.querySelector(".loader").style.display = "none";
    }, 500);
    if (!user) {
      setTimeout(() => {
        window.location.hash = "#login";
        // document.querySelector(".js-login").classList.add("active");
      }, 3000);
    }
  } else {
    document.querySelector(".wrapper").style.opacity = 1;
    document.querySelector(".loader").style.opacity = 0;
  }

  async function createHabit(name, targetEl, index) {
    if (document.querySelector(".missing-habits")) {
      document.querySelector(".missing-habits").remove();
    }

    const exists = await pb.collection("habits").getList(1, 100, {
      filter: `habit = "${name.toLowerCase()}"`,
    });
    const habit = exists?.items?.[0] || {};
    console.log(habit);
    if (!habit?.id || !exists) {
      try {
        const created = await pb
          .collection("habits")
          .create({ habit: name.toLowerCase(), user: user.id, year: new Date().getFullYear() });
        console.log({ created });
        habit.id = created.id;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    }

    const target = document.querySelector(targetEl);
    const grid = new Array(364).fill(0).map((a, i) => (a = a + i));
    const currentDay = daysIntoYear(new Date());

    const container = document.createElement("div");
    container.className = "grid";
    grid.forEach((g) => {
      const gridId = g;
      const div = document.createElement("button");
      div.innerHTML = "&nbsp";
      div.id = "day_" + gridId;
      div.dataset.id = gridId;
      if (gridId !== currentDay - 1) div.dataset.disabled = true;
      div.classList = "cell";
      div.dataset.grid = index;

      //get all the ids from inside habit.completed, split them into an array, and check if the current gridId is in the array then id so we can set the background color
      if (habit.completed) {
        const completed = habit.completed.split(",");
        if (completed.includes(gridId.toString())) {
          div.dataset.completed = true;
        }
      }

      async function updateItem(item) {
        if (item.dataset.completed && item.dataset.completed === "true") {
          item.dataset.completed = false;
          //remove this id from the habit.completed string
          const completed = habit.completed.split(",");
          const index = completed.indexOf(gridId.toString());
          if (index > -1) {
            completed.splice(index, 1);
            habit.completed = completed.join(",");
            const removed = await pb.collection("habits").update(habit.id, habit);
            console.log(removed);
          }
        } else {
          if (!habit.completed) {
            habit.completed = "";
            habit.completed += gridId;
          } else {
            habit.completed += "," + gridId;
          }
          item.dataset.completed = true;
          try {
            const update = await pb.collection("habits").update(habit.id, habit);
            console.log(update);
          } catch (error) {
            alert("There was an error updating your habit. Please try again.");
          }
        }
      }

      div.addEventListener("click", async (e) => {
        if (+e.target.dataset.id !== currentDay - 1) {
          alert("You only track habits on the current day.");
          return;
        }
        await updateItem(e.target);
      });

      div.addEventListener("contextmenu", async (e) => {
        e.preventDefault();
        await updateItem(e.target);
      });

      container.appendChild(div);
    });
    const wrapper = document.createElement("div");
    wrapper.classList  = "grid-wrapper";
    const heading = document.createElement("h2");
    heading.className = "grid-heading";
    heading.title = "Right-click to delete this habit."

    heading.addEventListener("contextmenu", async (e) => {
      e.preventDefault();
      const confirm = window.confirm("Are you sure you want to delete this habit?");
      if (confirm) {
        try {
          const deleted = await pb.collection("habits").delete(habit.id);
          if (deleted) {
            wrapper.remove();
          }
        } catch (error) {
          alert("There was an error deleting your habit. Please try again.");
          console.log(error);
        }

      }
    });

    heading.innerText = name.toUpperCase();
    wrapper.appendChild(heading);
    wrapper.appendChild(container);
    target.appendChild(wrapper);

    // habits[name].forEach((e) => {
    //   document.querySelector("#day_" + e).style.background = "white";
    //   document.querySelector("#day_" + e).dataset.complete = true;
    // });

    document
      .querySelectorAll(`#day_${currentDay - 1}`)
      .forEach((el) => (el.style.outline = "solid thin snow"));

    const priorDays = currentDay - 1;
    console.log(priorDays);
  }
})();

function daysIntoYear(date) {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
}
